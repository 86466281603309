import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaCloudUploadAlt, FaImage } from "react-icons/fa";
import BASE_URL from "../constant";
import LoadingPopup from "./LoadingPopup";
import { t } from "i18next";

const UploadBooksForm = () => {
  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("");
  // const [pdfFile, setPdfFile] = useState(null);
  // const [selectedImages, setSelectedImages] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [bookName, setBookName] = useState("");
  const [bookAuthor, setBookAuthor] = useState("");
  const [bookDescription, setBookDescription] = useState("");
  const [bookSize, setBookSize] = useState("");
  const [bookCoverFormat, setBookCoverFormat] = useState("");
  const [bookQuantity, setBookQuantity] = useState("");
  const [bookPrice, setBookPrice] = useState("");
  const [shipFromAddress, setShipFromAddress] = useState("");
  const [pdfFile, setPdfFile] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [acknowledgment, setAcknowledgment] = useState(false);

  useEffect(() => {
    // Cleanup function to revoke the object URL when the component unmounts or images change
    return () => {
      selectedImages.forEach((image) => {
        URL.revokeObjectURL(image);
      });
    };
  }, [selectedImages]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    //const imagePreviews = files.map((file) => URL.createObjectURL(file));
    setSelectedImages(files);
  };
  const [loading, setLoading] = useState(false);

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleFileChange = (e) => setPdfFile(e.target.files[0]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();
    if (!acknowledgment) {
      alert("Please Accept the acknowledgment.");
      return;
    }
    if (selectedImages.length === 0) {
      alert("Please upload at least one image and a maximum of 5.");
      setLoading(false);
      return;
    }
    if (selectedImages.length < 2) {
      alert("Please Add Minimum 2 and Max 5 .");
      return;
    }
    if (!name || !email || !phone ) {
      alert("Please fill in all required fields.");
      return;
    }
    if (!isValidEmail(email)) {
      alert("Please enter a valid email address.");
      return;
    }
    if (!pdfFile) {
      alert("Please upload a PDF file.");
      return;
    }
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("address", address);
    formData.append("book_name", bookName);
    formData.append("book_des", bookDescription);
    formData.append("book_cover", bookCoverFormat);
    formData.append("book_size", bookSize);
    formData.append("book_price", bookPrice);
    formData.append("book_author", bookAuthor);
    formData.append("book_available_quantity", bookQuantity);
    formData.append("book_shipping_address", shipFromAddress);

   
    formData.append("pdf_file", pdfFile);
    if (selectedImages.length === 0) {
      alert("Please upload at least one image and a max of 5.");
      return;
    }
    selectedImages.forEach((image) => {
      formData.append(`book_pictures[]`, image);
    });
    formData.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });
    try {
      const response = await axios.post(
        `${BASE_URL}/api_user_upload_books.php`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        alert("Your book and images have been uploaded successfully!");
        setName("");
        setEmail("");
        setPhone("");
        setAddress("");
        setBookName("");
        setBookAuthor("");
        setBookDescription("");
        setBookSize("");
        setBookCoverFormat("");
        setBookQuantity("");
        setBookPrice("");
        setShipFromAddress("");
        setPdfFile(null);
        setSelectedImages([]);
        setAcknowledgment(false);
        // setName("");
        // setEmail("");
        // setPhone("");
        // setPdfFile(null);
        // setSelectedImages([]);
      } else {
        alert("Failed to upload. Please try again later.");
      }
    } catch (error) {
      console.log(error.response || error);
      alert("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center py-10 bg-gray-100">
      {loading && <LoadingPopup />}
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-3xl">
        <h1 className="text-2xl font-bold mb-6 text-gray-800 text-center">
          {t("upload_book")}
        </h1>

        <form onSubmit={handleSubmit}>
          <h2 className="text-xl font-bold mb-6 text-gray-800 text-center">
            {t("book_info")}
            {/* Book Information */}
          </h2>

          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="bookName"
            >
              {t("book_name")} <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="bookName"
              placeholder={t("enter_book_name")}
              value={bookName}
              onChange={(e) => setBookName(e.target.value)}
              className="w-full border border-gray-300 rounded-md px-4 py-2"
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="bookAuthor"
            >
              {t("author")}
            </label>
            <input
              type="text"
              id="bookAuthor"
              placeholder={t("place_auther")}
              value={bookAuthor}
              onChange={(e) => setBookAuthor(e.target.value)}
              className="w-full border border-gray-300 rounded-md px-4 py-2"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="bookDescription"
            >
              {t("description")}
            </label>
            <textarea
              id="bookDescription"
              placeholder={t("place_des")}
              value={bookDescription}
              onChange={(e) => setBookDescription(e.target.value)}
              className="w-full border border-gray-300 rounded-md px-4 py-2"
              rows="4"
            ></textarea>
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="bookSize"
            >
              {t("book_size")}
            </label>
            <input
              type="text"
              id="bookSize"
              placeholder={t("place_size")}
              value={bookSize}
              onChange={(e) => setBookSize(e.target.value)}
              className="w-full border border-gray-300 rounded-md px-4 py-2"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="bookCoverFormat"
            >
              {t("book_cover")}
            </label>
            <input
              type="text"
              id="bookCoverFormat"
              value={bookCoverFormat}
              onChange={(e) => setBookCoverFormat(e.target.value)}
              className="w-full border border-gray-300 rounded-md px-4 py-2"
              placeholder={t("place_book_cover")}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="bookQuantity"
            >
              {t("quantity")}
            </label>
            <input
              type="number"
              id="bookQuantity"
              value={bookQuantity}
              onChange={(e) => setBookQuantity(e.target.value)}
              className="w-full border border-gray-300 rounded-md px-4 py-2"
              placeholder={t("place_book_available")}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="bookPrice"
            >
              {t("price")}
            </label>
            <input
              type="number"
              id="bookPrice"
              placeholder={t("place_price")}
              value={bookPrice}
              onChange={(e) => setBookPrice(e.target.value)}
              className="w-full border border-gray-300 rounded-md px-4 py-2"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="shipFromAddress"
            >
              {t("ship_from_address")}
            </label>
            <input
              type="text"
              id="shipFromAddress"
              value={shipFromAddress}
              onChange={(e) => setShipFromAddress(e.target.value)}
              className="w-full border border-gray-300 rounded-md px-4 py-2"
              placeholder={t("place_ship_from_address")}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="pdf"
            >
              {t("upload_pdf_file")} <span className="text-red-500">*</span>
            </label>
            <label
              htmlFor="pdf"
              className="block w-full text-center text-gray-900 border border-gray-300 rounded-lg cursor-pointer focus:outline-none focus:ring-blue-500 focus:border-blue-500 py-2 bg-gray-100"
            >
              {pdfFile ? pdfFile.name : t("no_file")}{" "}
              {/* Display filename or default text */}
            </label>
            <input
              type="file"
              id="pdf"
              onChange={handleFileChange}
              className="hidden"
              accept=".pdf"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="images"
            >
              {t("upload_imges")}
            </label>
            {/* File Input as Icon Button */}
            <div className="flex items-center space-x-2">
              <label htmlFor="images" className="cursor-pointer">
                <FaImage className="text-3xl text-gray-700 hover:text-blue-500" />
              </label>
              <input
                type="file"
                id="images"
                onChange={handleImageChange}
                multiple
                className="hidden"
                accept="image/*"
              />
            </div>

            {/* Image Previews */}
            <div className="mt-4 grid grid-cols-2 gap-2">
              {selectedImages.length > 0 &&
                selectedImages.map((image, index) => (
                  <div key={index} className="relative w-full">
                    <img
                      src={URL.createObjectURL(image)} // Correcting here: use 'image' directly
                      alt={`Preview ${index + 1}`}
                      className="w-48 h-48 object-cover rounded-md"
                    />
                    <button
                      onClick={() => {
                        const newImages = selectedImages.filter(
                          (_, i) => i !== index
                        );
                        setSelectedImages(newImages);
                      }}
                      className="w-8 h-8 absolute top-0 right-36 text-white bg-red-500 rounded-full p-1 text-sm"
                    >
                      X
                    </button>
                  </div>
                ))}
            </div>
          </div>
          <h2 className="text-xl font-bold mb-6 text-gray-800 text-center">
            {/* {t("upload_book")} */}
            {t("personal_info")}
          </h2>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="name"
            >
              {t("name")} <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full border border-gray-300 rounded-md px-4 py-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder={t("enter_your_name")}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="email"
            >
              {t("email")} <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full border border-gray-300 rounded-md px-4 py-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder={t("enter_your_email")}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="message"
            >
              {t("phone")}
            </label>
            <input
              type="tel"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="w-full border border-gray-300 rounded-md px-4 py-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder={t("enter_your_phone_no")}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="address"
            >
              {t("address")}
            </label>
            <input
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder={t("enter_add")}
              className="w-full border border-gray-300 rounded-md px-4 py-2"
            />
          </div>
          <div className="mb-4">
            <label className="flex items-start">
              <input
                type="checkbox"
                checked={acknowledgment}
                onChange={(e) => setAcknowledgment(e.target.checked)}
                className="mr-2 mt-1"
              />
              <div>
                <span>{t("acknowledgment_text_title")}</span>
                <div className="mt-2">
                  <ul className="list-disc pl-5 text-sm font-medium">
                    <li>{t("acknowledgment_text_point_1")}</li>
                    <li>{t("acknowledgment_text_point_2")}</li>
                    <li>{t("acknowledgment_text_point_3")}</li>
                    <li>{t("acknowledgment_text_point_4")}</li>
                  </ul>
                </div>
              </div>
            </label>
          </div>

          <button
            type="submit"
            className="w-full bg-red-800 text-white py-2 px-4 rounded-lg hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 flex items-center justify-center"
          >
            <FaCloudUploadAlt className="mr-2" />
            {t("upload")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UploadBooksForm;
