import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import BASE_URL from "../constant";
import { FaOpencart, FaPrint, FaSearch ,faSearchPlus, faSearchMinus, faUnd, FaSearchPlus, FaSearchMinus, FaUndo} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import htmlToPlainText from "../htmltotext";


const ImageModal = ({ isOpen, onClose, imageSrc }) => {
  const { t } = useTranslation();
  const [scale, setScale] = useState(1);

  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 3));
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 0.5));
  };

  const handleResetZoom = () => {
    setScale(1);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
     
      <div className="relative bg-white p-4 rounded max-h-[100vh]">
      <button onClick={onClose} className="absolute top-2 right-2 bg-red-900 text-white px-2 rounded-full"  title="Close Window">
        X
      </button>
      <br/>
        <div className="flex justify-center items-center overflow-auto max-h-[80vh]">
          <img
            src={imageSrc}
            alt="Preview"
            style={{ transform: `scale(${scale})` }}
            className="max-w-full max-h-[80vh] transition-transform duration-200"
          />
        </div>
        <div className="flex justify-center mt-2">
          <button
            onClick={handleZoomIn}
            className="mx-2 bg-red-900 text-white px-4 py-2 rounded-full"
            title="Zoom In"
          >
            <FaSearchPlus />
          </button>
          <button
            onClick={handleZoomOut}
            className="mx-2 bg-red-900 text-white px-4 py-2 rounded-full"
            title="Zoom Out"
          >
            <FaSearchMinus />
          </button>
          <button
            onClick={handleResetZoom}
            className="mx-2 bg-gray-500 text-white px-4 py-2 rounded-full"
            title="Reset Zoom"
          >
            <FaUndo />
          </button>
        </div>
       
      </div>
    </div>
  );
};






const MyPrintables = () => {
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState([]);
  const [page_no, setPageNo] = useState();

  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const { getUserData } = useContext(AuthContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentImageSrc, setCurrentImageSrc] = useState("");
  const [hebrewSort, setHebrewSort] = useState(false);
  const [alllang, setAllLang] = useState(
    localStorage.getItem("language") === "en" ? "All" : "הכל" || "All"
  );

  useEffect(() => {
    const userdata = getUserData();
    console.log(userdata.id);  // Ensure the userdata has an id field

    GetPrints(userdata);   GetCategories();
  }, []);
  const updatetitles = async (orderData) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api_book.php/getBooksByCategory?category=${orderData}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("API Response:", response.data); // Log the entire response

      if (Array.isArray(response.data.books)) {
        // Extract the books titles from the API response and clean them to plain text
        let books = response.data.books.map((book) =>
          htmlToPlainText(book).trim().toLowerCase()
        );

        console.log("Books after cleanup:", books); // Log the cleaned book titles

        // Check if invoices array is populated
        console.log("Invoices:", invoices); // Log the invoices array

        // Filter the invoices to only include those whose title matches any book
        const filteredInvoices = invoices.filter((invoice) => {
          // Clean the invoice title to plain text and lowercase
          const cleanedTitle = htmlToPlainText(invoice.title)
            .trim()
            .toLowerCase();

          console.log("Checking invoice:", cleanedTitle); // Log each invoice title for debugging

          // Check if the cleaned invoice title matches any of the book titles
          const match = books.includes(cleanedTitle);
          console.log("Match found:", match, "for invoice:", cleanedTitle);

          return match;
        });

        console.log("Filtered Invoices:", filteredInvoices); // Log the filtered invoices

        // Update the state with the filtered invoices
        // setInvoices(filteredInvoices);
        setFilteredInvoices(filteredInvoices);
      } else {
        console.error("Expected an array of books from the API");
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const GetCategories = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api_categories.php`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (Array.isArray(response.data)) {
        setSelectedCategories(response.data);
      } else {
        console.error("Expected an array of books");
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
  const GetPrints = async (orderData) => {
    if (!orderData || !orderData.id) {
      console.error("Invalid order data or missing ID");
      return;
    }
  
    try {
      const response = await axios.get(
        `${BASE_URL}/api_print.php?user_id=${orderData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("Prints successfully fetched:", response.data);
      if (Array.isArray(response.data)) {
        setInvoices(response.data);
        setFilteredInvoices(response.data);
      } else {
        console.error("Expected an array of books");
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const filterInvoices = () => {
    let filtered = invoices;

    if (searchTerm) {
      filtered = filtered.filter((item) =>
        htmlToPlainText(item.title)
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    }

    setFilteredInvoices(filtered);
  };

  useEffect(() => {
    filterInvoices();
  }, [searchTerm]);
  useEffect(() => {
    if (selectedCategory != "All" && selectedCategory!="" ) {
     
      updatetitles(selectedCategory);
    } else {
      setFilteredInvoices(invoices);
    }
  }, [selectedCategory]);
  useEffect(() => {
    const svLang = localStorage.getItem("language");
    setAllLang(svLang === "en" ? "All" : "הכל");
  });

  useEffect(() => {
    if (hebrewSort) {
      // Check if hebrewSort is truthy (true, non-zero, etc.)
      const sortedBooks = [...filteredInvoices].sort(hebrewSorter); // Make a shallow copy of the array before sorting
      console.log(sortedBooks);
      setFilteredInvoices(sortedBooks);
    } else {
      setFilteredInvoices(invoices);
    }
  }, [hebrewSort]);
  useEffect(() => {
    if (page_no) {
      const filteredInvoices = invoices.filter((invoice) => {
        console.log("Checking invoice page number:", invoice.page_no); // Log each invoice page number for debugging

        // Check if the invoice page number matches the provided page number
        const match = invoice.page_no == page_no;
        console.log(
          "Match found:",
          match,
          "for invoice page number:",
          invoice.page_no
        );

        return match;
      });

      // Update filtered invoices
      setFilteredInvoices(filteredInvoices);
    } else {
      setFilteredInvoices(invoices); // If no page_no is provided, show all invoices
    }
  }, [page_no, invoices]); // Add dependencies for `invoices` and `page_no` to ensure the filter is recalculated correctly

  function hebrewSorter(a, b) {
    const hebrewOrder = {
      א: 1,
      ב: 2,
      ג: 3,
      ד: 4,
      ה: 5,
      ו: 6,
      ז: 7,
      ח: 8,
      ט: 9,
      י: 10,
      כ: 11,
      ל: 12,
      מ: 13,
      נ: 14,
      ס: 15,
      ע: 16,
      פ: 17,
      צ: 18,
      ק: 19,
      ר: 20,
      ש: 21,
      ת: 22,
    };

    // Remove HTML tags and get the first letter of each title
    const aTitle = htmlToPlainText(a.title);
    const bTitle = htmlToPlainText(b.title);

    const aFirstLetter = aTitle.charAt(0);
    const bFirstLetter = bTitle.charAt(0);

    // Perform comparison based on the Hebrew order
    if (hebrewOrder[aFirstLetter] && hebrewOrder[bFirstLetter]) {
      if (hebrewOrder[aFirstLetter] === hebrewOrder[bFirstLetter]) {
        return 0;
      }

      return hebrewOrder[aFirstLetter] < hebrewOrder[bFirstLetter] ? -1 : 1;
    }

    // Handle cases where the first letter is not in the Hebrew order array
    return 0; // You can modify this part to define behavior for such cases
  }

  const printPage = (imageSrc, title) => {
    const printWindow = window.open("", "", "height=600,width=800");

    // Create the HTML structure with inline CSS to control the print layout
    const printContent = `
        <html>
       //<meta http-equiv="Content-Security-Policy" content="script-src 'self'">

          <head>
          
            <title>${title}</title>
            
            <style>
              @media print {
                @page {
                  size: auto;
                  margin: 0;
                }
                html, body {
                  width: 100%;
                  height: 100%;
                  margin: 0;
                  padding: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                canvas {
                  max-width: 100%;
                  max-height: 100%;
                }
              }
            </style>
          </head>
          <body>
            <canvas id="printCanvas"></canvas>
            <script>
              const canvas = document.getElementById('printCanvas');
              const ctx = canvas.getContext('2d');
              const img = new Image();
              img.src = '${imageSrc}';
             img.onload = function() {
  canvas.width = img.width;
  canvas.height = img.height;
  ctx.drawImage(img, 0, 0);
  setTimeout(() => {
    window.print();
    window.close();
  }, 100); // Small delay before printing
};
            </script>
          </body>
        </html>
      `;

    printWindow.document.open();
    printWindow.document.write(printContent);
    printWindow.document.close();
  };

  const viewPage = (imageSrc) => {
    setCurrentImageSrc(imageSrc); // Set the current image source for the modal
    setModalOpen(true); // Open the modal to view the image
  };

  return (
    <>
      <div className="flex flex-col md:flex-row items-center justify-between px-20 py-4 space-y-4 md:space-y-0 bg-red-900 rounded-lg shadow-lg">
        {/* Search Book */}
        <label htmlFor="searchBook" className="font-medium text-white">
          {t("search_book_print")}
        </label>
        <input
          type="text"
          placeholder={t("search")}
          className="p-2 border rounded w-full md:w-1/2 bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <div className="flex flex-col space-y-6 px-20 py-4">
          {/* Select Category and Hebrew Sort Toggle */}
          <div className="flex flex-col md:flex-row items-center space-y-4 md:space-x-4 md:space-y-0">
            <div className="flex flex-col space-y-2">
              <label htmlFor="category" className="font-medium text-white">
                {t("select_cate")}
              </label>
              <select
                id="category"
                className="p-2 border rounded w-full  bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={selectedCategory}
                onChange={(e) => {
                  console.log(e.target.value);
                  setSelectedCategory(e.target.value);
                }}
              >
                <option value="All">{alllang}</option>
                {selectedCategories.map((category, index) => (
                  <option key={index} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Input Fields for Page Range */}
          <div className="flex space-x-4 w-full">
            {/* From Page Input */}
            <div className="flex flex-col w-full md:w-1/2">
              <label htmlFor="fromPage" className="font-medium text-white">
                {t("by_page")}
              </label>
              <input
                id="fromPage"
                type="text"
                placeholder={t("page")}
                className="p-2 border rounded w-full bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={page_no}
                onChange={(e) => setPageNo(e.target.value)}
              />
            </div>

            {/* Hebrew Sort Toggle */}
            <div className="flex flex-col items-center space-y-2 w-full md:w-1/2">
              <label htmlFor="hebrewSort" className="font-medium text-white">
                {t("order_hebrew")}
              </label>
              <input
                id="hebrewSort"
                type="checkbox"
                className="w-6 h-6"
                checked={hebrewSort}
                onChange={() => setHebrewSort(!hebrewSort)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-8 py-[40px] px-20">
        {filteredInvoices.length > 0 ? (
          filteredInvoices.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center p-4 border rounded-lg shadow"
            >
              <div className="relative mb-4 w-full h-100 rounded">
                {item.page_path ? (
                  <img
                    src={`${BASE_URL}/${item.page_path}`}
                    alt={htmlToPlainText(item.title)}
                    className="w-full h-full object-cover rounded"
                  />
                ) : (
                  <div className="flex items-center justify-center w-full h-full">
                    <svg
                      fill="#000000"
                      width="800px"
                      height="800px"
                      viewBox="0 0 32 32"
                      className="h-full w-full object-cover rounded"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <defs>
                        <style>{`.cls-1{fill:none;}`}</style>
                      </defs>
                      <title>no-image</title>
                      <path d="M30,3.4141,28.5859,2,2,28.5859,3.4141,30l2-2H26a2.0027,2.0027,0,0,0,2-2V5.4141ZM26,26H7.4141l7.7929-7.793,2.3788,2.3787a2,2,0,0,0,2.8284,0L22,19l4,3.9973Zm0-5.8318-2.5858-2.5859a2,2,0,0,0-2.8284,0L19,19.1682l-2.377-2.3771L26,7.4141Z" />
                      <path d="M6,22V19l5-4.9966,1.3733,1.3733,1.4159-1.416-1.375-1.375a2,2,0,0,0-2.8284,0L6,16.1716V6H22V4H6A2.002,2.002,0,0,0,4,6V22Z" />
                      <rect
                        id="_Transparent_Rectangle_"
                        data-name="&lt;Transparent Rectangle&gt;"
                        className="cls-1"
                        width="32"
                        height="32"
                      />
                    </svg>
                    <span className="absolute text-white bg-black bg-opacity-50 p-2 rounded">
                      {t("alert")}
                    </span>
                  </div>
                )}
              </div>
              <h3 className="text-lg font-semibold">
                {t("book")}
                {htmlToPlainText(item.title)}
              </h3>
              <h4 className="text-m font-semibold">
                {t("page")} :{htmlToPlainText(item.page_no)}
              </h4>
              <div className="grid grid-cols-2 gap-4 mb-2">
                <button
                  className="p-2 bg-red-500 rounded-full text-white"
                  onClick={() => {
                    if (item.page_path) {
                      viewPage(`${BASE_URL}/${item.page_path}`);
                    } else {
                      alert(t("alert1"));
                    }
                  }}
                >
                  <FaSearch />
                </button>
                <button
                  className="p-2 bg-[#fde5c3c4] rounded-full text-black"
                  onClick={() => {
                    if (item.page_path) {
                      printPage(`${BASE_URL}/${item.page_path}`, item.title);
                    } else {
                      alert(t("alert1"));
                    }
                  }} // Fix: Pass the function correctly
                >
                  <FaPrint />
                </button>
              </div>
            </div>
          ))
        ) : (
          <p> {t("noitemfound")}</p>
        )}
      </div>

      {/* Modal for viewing images */}
      <ImageModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        imageSrc={currentImageSrc}
      />
    </>
  );
};

export default MyPrintables;
