import { createContext, useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import axios from "axios";
import BASE_URL from "../constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import htmlToPlainText from "../htmltotext";
import { getDatabase, ref, onValue, update } from "firebase/database";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [cart, setCart] = useState(null);
  const print_price = 0.1;
  const print_price_is = 20.0;

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      console.log(user);

      if (user) {
        const userData = getUserData();
        // Fetch the user's cart from the server when the user is authenticated
        fetchUserCart(userData);
      }
    });

    return () => {
      unsub();
    };
  }, []);

  const saveUserData = (userData) => {
    setCurrentUser((prevUser) => ({
      ...prevUser,
      ...userData,
    }));

    localStorage.setItem(
      "userData",
      JSON.stringify({ ...currentUser, ...userData })
    );
  };

  const getUserData = () => {
    const storedUserData = localStorage.getItem("userData");
    return storedUserData ? JSON.parse(storedUserData) : {};
  };

  const emptyCurrentUser = () => {
    setCurrentUser({});
    localStorage.removeItem("userData");
    setCart(null); // Clear the cart when user logs out
  };

  const fetchUserCart = async (userData) => {
    try {
      // Try to fetch the cart for the given user
      const response = await axios.get(
        `${BASE_URL}/api_cart.php?action=cart&user_id=${userData.id}`
      );

      if (
        response.data.status === "success" &&
        response.data.carts.length > 0
      ) {
        
        setCart(response.data.carts[0]);
      } else {
        // If no cart exists, create a new one
        const newCart = {
          type: "cart",
          user_id: userData.id,
          discount: 0,
          sub_total: 0,
          final_amount: 0,
          promo_code: null,
        };

        const createResponse = await axios.post(
          `${BASE_URL}/api_cart.php?action=cart`,
          newCart
        );

        if (createResponse.data.status === "success") {
          fetchUserCart(userData);
          // Set the newly created cart
          // setCart({
          //   ...newCart,
          //   id: createResponse.data.data.cart_id, // Add the new cart ID from the response
          // });
        } else {
          // Handle the error if cart creation fails
          console.error(
            "Failed to create a new cart:",
            createResponse.data.message
          );
        }
      }
    } catch (error) {
      // Handle any errors that occur during the fetch or creation process
      console.error("Error fetching or creating the cart:", error);
    }
  };
  const addPageToDb = async (cartData) => {
    console.log(cartData);
    const response = await axios.post(
      `${BASE_URL}/api_print.php`,
      cartData
    );
    console.log(response.data);
    response.data.insertedIds.forEach(async (item) => {
      await addPageToCart({ paid: item, quantity: 1 });
      // pageList.push({
      //   user_id: userData.id,
      //   book_id: content.id,
      //   page_no: content.pageNumber,
      // });
    });
    // addPageToCart({paid:response.data.insertedId,
    //   quantity:1,
    // })
  };
  const notificationSound = new Audio("/audio/notification_sound.mp3");

  useEffect(() => {
    const userData = getUserData();
    const userNotifyRef = ref(db, `notify/${userData.fb_id}`);

    // Real-time listener for the user's notifications
    const unsubscribe = onValue(userNotifyRef, (snapshot) => {
      const notifications = snapshot.val();

      if (notifications) {
        Object.entries(notifications).forEach(
          ([notificationId, notificationData]) => {
            if (notificationData.notify === true) {
              // Play notification sound
              notificationSound.play().catch((error) => {
                console.error("Error playing notification sound:", error);
              });

              // Show toast notification with title, message, and custom duration
              toast.info(
                `${notificationData.title}: ${notificationData.message}`,
                {
                  autoClose: 10000, // 5 seconds
                  position: "top-right",
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                }
              );

              // Update `notify` to false for this notification
              update(ref(db, `notify/${userData.fb_id}/${notificationId}`), {
                notify: false,
              }).catch((error) =>
                console.error("Error updating notify flag:", error)
              );
            }
          }
        );
      }
    });

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, []);

  const addPageToCart = async (cartData) => {
    //  console.log(cartData);
    console.log(cart.details);
    try {
      // First, check if the book_id is already present in the cart details
      // const existingCartDetail = cart.details.find(detail => detail.paid == cartData.paid);

      // if (existingCartDetail) {
      //   // Book already exists in the cart, so update the quantity and prices
      //   const newQuantity = existingCartDetail.quantity + cartData.quantity;
      //   const updatedSubTotal = parseFloat(cart.sub_total) + parseFloat(print_price);
      //   const updatedFinalAmount = parseFloat(cart.final_amount) + parseFloat(print_price);
      //   console.log({ quantity: newQuantity,updatedSubTotal:updatedSubTotal, updatedFinalAmount:updatedFinalAmount})

      //   // Make API call to update the existing cart detail
      //   const response = await axios.put(`${BASE_URL}/api_cart.php?action=cart&id=${existingCartDetail.id}`, {

      //     quantity: newQuantity
      //   });

      //   if (response.data.status === 'success') {
      //     // Update the cart's totals in the cart table
      //     await updateCartTotals(updatedSubTotal, updatedFinalAmount);
      //     // toast.success(`Updated quantity for ${htmlToPlainText(cartData.product.title)}`);
      //   } else {
      //     // toast.error(`Error updating cart detail: ${response.data.message}`);
      //   }

      // }

      // Book is not in the cart, so add it as a new cart detail
      const response = await axios.post(
        `${BASE_URL}/api_cart.php?action=cart`,
        {
          type: "cart_detail",
          cart_id: cart.id,
          book_id: null,
          paid: cartData.paid,
          quantity: cartData.quantity,
        }
      );

      if (response.data.status === "success") {
        // Recalculate totals after adding the new item
        const updatedSubTotal =
          parseFloat(cart.sub_total) + parseFloat(print_price);
        const updatedFinalAmount =
          parseFloat(cart.final_amount) + parseFloat(print_price);

        // Update the cart's totals in the cart table
        // await updateCartTotals(updatedSubTotal, updatedFinalAmount);
        toast.success(
          `Print Page added to the cart`
        );
        
      } else {
        // toast.error(`Error adding to cart: ${response.data.message}`);
      }

      // After every update, fetch the updated cart
      fetchUserCart(getUserData());
      // toast.success(`book page  added to the cart`);
    } catch (error) {
      console.error("Error adding to cart:", error);
      toast.error("Error adding to cart");
    }
    // }
  };

  const addToCart = async (cartData) => {
    //  console.log(cartData);
    console.log(cart.details);
    if (cartData.items_id && cartData.items_id.length > 0) {
      console.log(cartData);

      try {
        // Iterate over each item in the items_id array
        for (const [index, itemId] of cartData.items_id.entries()) {
          // Determine the book_id based on itemId
          const bookId =
            itemId === "default-id"
              ? cartData.product.id
              : `${cartData.product.id}-vol-${itemId}`;
          console.log(bookId);
          // Check if the book_id is already present in the cart details
          const existingCartDetail = cart.details.find(
            (detail) => detail.book_id === bookId
          );

          if (existingCartDetail) {
            // Book already exists in the cart, so update the quantity and prices
            const newQuantity =
              existingCartDetail.quantity + cartData.items_quantity[index];
            const updatedSubTotal =
              parseFloat(cart.sub_total) + parseFloat(cartData.price);
            const updatedFinalAmount =
              parseFloat(cart.final_amount) + parseFloat(cartData.price);

            // Make API call to update the existing cart detail
            const response = await axios.put(
              `${BASE_URL}/api_cart.php?action=cart&id=${existingCartDetail.id}`,
              {
                quantity: newQuantity,
              }
            );

            if (response.data.status === "success") {
              // Update the cart's totals in the cart table
              // await updateCartTotals(updatedSubTotal, updatedFinalAmount);
              //  toast.success(`Updated quantity for ${htmlToPlainText(cartData.product.title)}`);
            } else {
              // toast.error(`Error updating cart detail: ${response.data.message}`);
            }
          } else {
            // Book is not in the cart, so add it as a new cart detail
            const response = await axios.post(
              `${BASE_URL}/api_cart.php?action=cart`,
              {
                type: "cart_detail",
                cart_id: cart.id,
                book_id: bookId,
                paid: cartData.paid,
                quantity: cartData.items_quantity[index],
              }
            );

            if (response.data.status === "success") {
              // Recalculate totals after adding the new item
              const updatedSubTotal =
                parseFloat(cart.sub_total) + parseFloat(cartData.price);
              const updatedFinalAmount =
                parseFloat(cart.final_amount) + parseFloat(cartData.price);

              // Update the cart's totals in the cart table
              // await updateCartTotals(updatedSubTotal, updatedFinalAmount);
              // toast.success(`${htmlToPlainText(cartData.product.title)} added to the cart`);
            } else {
              // toast.error(`Error adding to cart: ${response.data.message}`);
            }
          }
        }

        // After all updates, fetch the updated cart
        await fetchUserCart(getUserData());
        toast.success(
          `${htmlToPlainText(cartData.product.title) || "Print Page"} added to the cart`
        );
        
      } catch (error) {
        console.error("Error adding to cart:", error);
        toast.error("Error adding to cart");
      }
    } else {
      try {
        // First, check if the book_id is already present in the cart details
        const existingCartDetail = cart.details.find(
          (detail) => detail.book_id == cartData.product.id
        );

        if (existingCartDetail) {
          // Book already exists in the cart, so update the quantity and prices
          const newQuantity = existingCartDetail.quantity + cartData.quantity;
          const updatedSubTotal =
            parseFloat(cart.sub_total) + parseFloat(cartData.price);
          const updatedFinalAmount =
            parseFloat(cart.final_amount) + parseFloat(cartData.price);
          console.log({
            quantity: newQuantity,
            updatedSubTotal: updatedSubTotal,
            updatedFinalAmount: updatedFinalAmount,
          });

          // Make API call to update the existing cart detail
          const response = await axios.put(
            `${BASE_URL}/api_cart.php?action=cart&id=${existingCartDetail.id}`,
            {
              //type:"cart_datail",
              quantity: newQuantity,
            }
          );

          if (response.data.status === "success") {
            // Update the cart's totals in the cart table
            //  await updateCartTotals(updatedSubTotal, updatedFinalAmount);
            //  toast.success(`Updated quantity for ${htmlToPlainText(cartData.product.title)}`);
          } else {
            // toast.error(`Error updating cart detail: ${response.data.message}`);
          }
        } else {
          // Book is not in the cart, so add it as a new cart detail
          const response = await axios.post(
            `${BASE_URL}/api_cart.php?action=cart`,
            {
              type: "cart_detail",
              cart_id: cart.id,
              book_id: cartData.product.id,
              paid: cartData.paid,
              quantity: cartData.quantity,
            }
          );

          if (response.data.status === "success") {
            // Recalculate totals after adding the new item
            const updatedSubTotal =
              parseFloat(cart.sub_total) + parseFloat(cartData.price);
            const updatedFinalAmount =
              parseFloat(cart.final_amount) + parseFloat(cartData.price);

            // Update the cart's totals in the cart table
            // await updateCartTotals(updatedSubTotal, updatedFinalAmount);
            //  toast.success(`${htmlToPlainText(cartData.product.title)} added to the cart`);
          } else {
            // toast.error(`Error adding to cart: ${response.data.message}`);
          }
        }

        // After every update, fetch the updated cart
        fetchUserCart(getUserData());
        toast.success(
          `${htmlToPlainText(cartData.product.title) || "Print Page"} added to the cart`
        );
        
      } catch (error) {
        console.error("Error adding to cart:", error);
        toast.error("Error adding to cart");
      }
    }
  };

  // Helper function to update the cart's sub_total and final_amount
  const updateCartTotals = async (subTotal, finalAmount) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/api_cart.php?action=cart&id=${cart.id}`,
        {
          sub_total: subTotal,
          final_amount: finalAmount,
        }
      );

      if (response.data.status === "success") {
        console.log("Cart totals updated successfully");
      } else {
        console.error(`Error updating cart totals: ${response.data.message}`);
      }
    } catch (error) {
      console.error("Error updating cart totals:", error);
    }
  };

  const removeCartAndDetails = async (cartId) => {
    try {
      const url = `${BASE_URL}/api_cart.php?action=cart&cart_id=${cartId}`;

      const response = await axios.delete(url);

      if (response.data.status === "success") {
        console.log(
          "Cart and all details deleted successfully:",
          response.data.message
        );
        // Optionally update your frontend (e.g., refresh the cart list)
      } else {
        console.error(
          "Failed to delete cart and details:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error while deleting cart and details:", error);
    }
  };

  const removeFromCart = async (itemId) => {
    try {
      const url = `${BASE_URL}/api_cart.php?action=cart&id=${itemId.id}`;

      const response = await axios.delete(url);

      if (response.data.status === "success") {
        console.log(
          `Cart detail-id ${itemId.id} deleted successfully:`,
          response.data.message
        );

        const updatedSubTotal =
          parseFloat(cart.sub_total) -
          itemId.quantity * parseFloat(itemId.price);
        const updatedFinalAmount =
          parseFloat(cart.final_amount) -
          itemId.quantity * parseFloat(itemId.price);
        // await updateCartTotals(updatedSubTotal, updatedFinalAmount);
        await fetchUserCart(getUserData());
        // toast.success(
        //   `${htmlToPlainText(itemId.title)} sucessfully removed from cart`
        // );
        toast.success(
          `${htmlToPlainText(itemId.title) || "Print Page"} added to the cart`
        );
        
        // Optionally update your frontend (e.g., refresh the cart list)
      } else {
        console.error(
          "Failed to delete cart and details:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error while deleting cart and details:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        cart,
        saveUserData,
        getUserData,
        emptyCurrentUser,
        addPageToDb,
        addToCart,
        removeFromCart,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
