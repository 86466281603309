import React, { useEffect, useState, useContext } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import return_URL from "../stripe_constant";
import { AuthContext } from "../context/AuthContext";
import BASE_URL from "../constant";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

export default function CheckoutForm({ product, type, amount, currency }) {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { getUserData } = useContext(AuthContext);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    console.log(product);

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const insetOrder = async (orderData) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api_orders.php`,
        orderData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Order successfully placed:", response.data);
      return response.data;
      //  setOrderPlaced(true);
    } catch (error) {
      console.error("Error placing order:", error);
    }
  };
  function generateTransactionId(length = 16) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let transactionId = "OIZ-WALLET-";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      transactionId += characters[randomIndex];
    }
    return transactionId;
  }

  const insetCartOrder = async (orderData) => {
    console.log(orderData["cart_id"]);
    try {
      const response = await axios.post(
        `${BASE_URL}/api_porders.php?action=create_or_copy_order`,
        orderData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(" Cart Order successfully placed:", response.data);
      const url = `${BASE_URL}/api_cart.php?action=cart&cart_id=${orderData["cart_id"]}`;
      const response_delete = await axios.delete(url);
      console.log(response_delete);

      return response_delete.data;
      // await deletmycart();
      //  setOrderPlaced(true);
    } catch (error) {
      console.error("Error placing order:", error);
    }
  };
  const sendThankuEmail = async (data) => {
    const emaildata = JSON.stringify({
      email: data.email,
      name: data.name,
      orderId: data.orderId,
      totalAmount: data.totalAmount,
      currency: data.currency,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://oizvehadar.com/mailjs/send-thank-you-email",
      headers: {
        "Content-Type": "application/json",
      },
      data: emaildata,
    };

    try {
      const response = await axios.request(config);
      console.log(response.data);
    } catch (error) {
      console.error(error.message);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    //const productTitle = type === "subscription" ? product[0]?.title : type === "bundle" ? product.bundle_name : product.title;
    const productid =
      type === "subscription"
        ? product[0]?.id
        : type === "cart"
        ? product.id
        : product.id;
    console.log(productid);
    // Encode the productTitle to make it URL-safe
    // const encodedProductTitle = encodeURIComponent(productTitle);

    // Construct the thank-you URL dynamically
    const thankYouUrl = `${return_URL}thank-you/true/`;
    console.log(thankYouUrl);
    const payout = await stripe.confirmPayment({
      elements,
      confirmParams: {},
      redirect: "if_required",
    });

    console.log(payout);

    if (payout.error) {
      setMessage(payout.error.message);
    } else {
      const userdata = getUserData();
      if (type == "wallet-top-up") {
        setMessage(`Payment Succeessful..`);
        axios
          .post(`${BASE_URL}/api_wallet.php`, {
            user_id: userdata.id, // User ID
            amount: amount, // Amount to top-up
            transaction_type: product.transaction_type,
            transaction_id: payout.paymentIntent.id,
            transaction_msg: product.transaction_msg,
          })
          .then((response) => {
            if (response.data) {
              // Assuming success response contains a success flag
              sendThankuEmail({
                email: userdata.email,
                name: userdata.username,
                orderId: "Wallet-top-up Purchase",
                totalAmount: amount.toString(),
                currency:"$",
              });
              navigate("/User/User");
            } else {
              console.error(response.data.message || "Error topping up wallet");
            }
          })
          .catch((error) => {
            console.error("Error topping up wallet");
          });
      }
      // else if(type=='wallet-purchase'){

      // }
      else if (type == "subscription") {
        const orderData = {
          user_id: userdata.id, // Replace with dynamic user data if available
          total_price: amount,
          transaction_id: payout.paymentIntent.id,
          order_details: [
            {
              book_id: null,
              volume_id: null,
              subscription_id: type === "subscription" ? productid : null,
              bundle_id: type === "bundle" ? productid : null,
              price: amount,
            },
          ],
        };
        setMessage(`Payment Succeessful..`);
        const ret_val = await insetOrder(orderData);
        sendThankuEmail({
          email: userdata.email,
          name: userdata.username,
          orderId:"Subscriptions purchased",
          totalAmount: amount.toString(),
          currency: currency==="usd"?"$":"₪",
        });
        if (ret_val.status != "error") {
          navigate("/thank-you/true/");
        }
      } else if (type == "donation") {
        var formData = product;
        try {
          const response = await axios.post(
            `${BASE_URL}/api_donations.php/donations`,
            formData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          console.log("Server Response:", response.data);
          alert("Donation submitted successfully!");
        } catch (error) {
          console.error(
            "Error submitting form:",
            error.response?.data || error.message
          );
          // alert("An error occurred while submitting the form. Please try again.");
        }

        setMessage(`Payment Succeessful..`);

        navigate("/thank-you/true/");
      } else {
        const orderData = {
          user_id: userdata.id, // Replace with dynamic user data if available
          cart_id: productid,
          transaction_id: payout.paymentIntent.id,
          sub_total: amount,
          final_amount: amount,
          currency: currency,
          payment_mode: "Card",
        };
        setMessage(`Payment Succeessful..`);

        const ret_val = await insetCartOrder(orderData);
        sendThankuEmail({
          email: userdata.email,
          name: userdata.username,
          orderId: type === "cart"
? (product.title || "Print Purchase")
: (product.title || "Print Purchase"),
          totalAmount: amount.toString(),
          currency: currency==="usd"?"$":"₪",
        });
        if (ret_val.message != null) {
          navigate("/thank-you/true/");
        }
      }
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : t("pay_now")}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
