import React, { useState ,useEffect,useContext} from "react";
import { FaTruck, FaCheckCircle, FaRegClock } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import { FaBox } from "react-icons/fa6";
import { useTranslation } from 'react-i18next'; 
import { AuthContext } from "../context/AuthContext";
import BASE_URL from "../constant";
import axios from "axios";
import htmlToPlainText from "../htmltotext";

const MyOrder = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [filter, setFilter] = useState("all");
  const { t, i18n } = useTranslation();
  const { getUserData } = useContext(AuthContext);
  useEffect(() => {
    const userdata = getUserData();
    GetOrder(userdata);
  }, [getUserData]);

  const fetchOrderDetails = async (orderId) => {
    try {
      const response = await axios.get(`${BASE_URL}/api_porders.php?action=get_order_details&order_id=${orderId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Order details fetched:', response.data);
      // setSelectedOrder({ ...response.data.order, id: orderId });
      // setDeliveryStatus(response.data.order.delivery_status);
      // setTrackingId(response.data.order.tracking_id);
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  };
  const [orders, setOrders] = useState([]);
  const GetOrder = async (userData) => {
    try {
      const response = await axios.get(`${BASE_URL}/api_porders.php?action=get_user_orders&user_id=${userData.id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Orders successfully fetched:', response.data);
      setOrders(response.data.orders);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };
  // const orders = [
  //   {
  //     id: 1,
  //     items: [
  //       { name: "Book 1", price: "$999", image: "https://via.placeholder.com/50" },
  //       { name: "Book 2", price: "$49", image: "https://via.placeholder.com/50" },
  //     ],
  //     status: "Dispatched",
  //     trackingId: "TRK123456",
  //     customerName: "John Doe",
  //     address: "123 Main St, Anytown, USA",
  //     purchaseDate: "2024-10-01",
  //     paymentMethod: "Credit Card",
  //     deliveryAddress: "123 Main St, Anytown, USA",
  //     tax: "$50",
  //     deliveryCharges: "$10",
  //   },
  //   {
  //     id: 2,
  //     items: [
  //       { name: "Book 1", price: "$699", image: "https://via.placeholder.com/50" },
  //       { name: "Book 3", price: "$19", image: "https://via.placeholder.com/50" },
  //     ],
  //     status: "Delivered",
  //     trackingId: "TRK654321",
  //     customerName: "Jane Smith",
  //     address: "456 Elm St, Othertown, USA",
  //     purchaseDate: "2024-10-02",
  //     paymentMethod: "PayPal",
  //     deliveryAddress: "456 Elm St, Othertown, USA",
  //     tax: "$40",
  //     deliveryCharges: "$5",
  //   },
  //   {
  //     id: 3,
  //     items: [
  //       { name: "Book 5", price: "$199", image: "https://via.placeholder.com/50" },
  //     ],
  //     status: "Cancelled",
  //     trackingId: "TRK789012",
  //     customerName: "Alice Johnson",
  //     address: "789 Pine St, Differenttown, USA",
  //     purchaseDate: "2024-10-03",
  //     paymentMethod: "Credit Card",
  //     deliveryAddress: "789 Pine St, Differenttown, USA",
  //     tax: "$20",
  //     deliveryCharges: "$0",
  //   },
  // ];

  const statusStyles = {
    Booked: { base: "text-black", dark: "text-black" },
    Dispatched: { base: "text-black", dark: "text-yellow-500" },
    Delivered: { base: "text-black", dark: "text-green-500" },
  };
  const delivery_status_enum = (stat) => {
    switch (stat.toLowerCase()) {
      case 'pending':
        return 'Booked';
      case 'shipped':
        return 'Dispatched';
      case 'delivered':
        return 'Delivered';
      case 'cancelled':
        return 'Cancel';
      default:
        return 'Booked';
    }
  };
  
 

  const filteredOrders = orders && orders.length > 0 ? orders.filter((order) => {
    if (filter === "all") return true;
    if (filter === "active") return delivery_status_enum(order.delivery_status) !== "Delivered" && delivery_status_enum(order.delivery_status) !== "Cancel";
    if (filter === "completed") return delivery_status_enum(order.delivery_status) === "Delivered";
    if (filter === "cancelled") return delivery_status_enum(order.delivery_status) === "Cancel";
    return true;
  }) : []; // Return an empty array if orders is empty or undefined
  

  const handleViewDetails = (order) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  const getProgressWidth = (status) => {
    switch (status) {
      case "Delivered":
        return "w-full bg-green-500";
      case "Dispatched":
        return "w-2/3 bg-yellow-500";
      case "Booked":
        return "w-1/3 bg-black";
      default:
        return "w-1/3 bg-gray-300";
    }
  };


  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">{t('myorder')}</h1>

      <div className="grid grid-cols-4 border-b-2 py-6 text-center gap-4 mb-4">
  <button
    onClick={() => setFilter("all")}
    className={`border border-gray-200 rounded-lg shadow text-white px-4 py-4 mr-2 ${filter === "all" ? "bg-[#fdebd1]" : "bg-white"}`}
  >
    <div className="flex gap-4 align-center">
      <FaBox className={` text-[22px] ${filter === "all" ? "text-black" : "text-black"}`} />
      <span className={` text-[16px] ${filter === "all" ? "text-black" : "text-black"}`}>{t('Allorder')}</span>
    </div>
  </button>
  <button
    onClick={() => setFilter("active")}
    className={`border border-gray-200 rounded-lg shadow text-white px-4 py-4 mr-2 ${filter === "active" ? "bg-[#fdebd1]" : "bg-white"}`}
  >
    <div className="flex gap-4 align-center">
      <FaBox className={` text-[22px] ${filter === "active" ? "text-yellow-500" : "text-black"}`} />
      <span className={` text-[16px] ${filter === "active" ? "text-black" : "text-black"}`}>{t('ActiveOrder')}</span>
    </div>
  </button>
  <button
    onClick={() => setFilter("completed")}
    className={`border border-gray-200 rounded-lg shadow text-white px-4 py-4 mr-2 ${filter === "completed" ? "bg-[#fdebd1]" : "bg-white"}`}
  >
    <div className="flex gap-4 align-center">
      <FaBox className={` text-[22px] ${filter === "completed" ? "text-green-500" : "text-black"}`} />
      <span className={` text-[16px] ${filter === "completed" ? "text-black" : "text-black"}`}>{t('Completed')}</span>
    </div>
  </button>
  <button
    onClick={() => setFilter("cancelled")}
    className={`border border-gray-200 rounded-lg shadow text-white px-4 py-4 mr-2 ${filter === "cancelled" ? "bg-[#fdebd1]" : "bg-white"}`}
  >
    <div className="flex gap-4 align-center">
      <FaBox className={` text-[22px] ${filter === "cancelled" ? "text-red-500" : "text-black"}`} />
      <span className={` text-[16px] ${filter === "cancelled" ? "text-black" : "text-black"}`}>{t('Cancelled')}</span>
    </div>
  </button>
</div>

      <div class="relative max-h-[600px] overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-sm text-left rtl:text-right text-black ">
      <thead class="text-xs text-black uppercase bg-[#fdebd1]">
          <tr className="bg-[#fdebd1] rtl:text-right text-left">
            <th className="p-4 border-b">{t('ID')}</th>
            <th className="p-4 border-b">{t('items')}</th>
            <th className="p-4 border-b">{t('TotalPrice')}</th>
            <th className="p-4 border-b">{t('Status')}</th>
            {/* <th className="p-4 border-b">{t('TrackingID')}</th> */}
            <th className="p-4 border-b">{t('actions')}</th>
          </tr>
        </thead>
        <tbody className="rtl:text-right ">
        {filteredOrders && filteredOrders.length > 0 ? (
filteredOrders.map((order) => (
  <tr key={order.id}>
    <td className="p-4 border-b">{order.id}</td>
    <td className="p-4 border-b">
    {order.details && htmlToPlainText(order.details[0]?.title??"Unknow Title") } ....
    </td>
    <td className="p-4 border-b">${order.final_amount}</td>
    <td className="p-4 border-b">
      {delivery_status_enum(order.delivery_status)}{" "}
    </td>
    {/* <td className="p-4 border-b">{order.tracking_id}</td> */}
    <td className="p-4 border-b">
      <button
        onClick={() => handleViewDetails(order)}
        className="px-4 py-1  text-white text-sm focus:outline-none focus:ring-4 rounded-lg hover:bg-[#733d17] shadow bg-[#3b200f]"
      >
        {t('view_details')}
      </button>
    </td>
  </tr>
))
) : (
  <tr>
    <td colSpan="5" className="text-center py-4">
    {t('noitemfound')}
    </td>
  </tr>
)}
          
        </tbody>
      </table>
</div>
      {isModalOpen && selectedOrder && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-lg w-[80%]">
            {/* Status Bar with Icons */}
            <div className="flex justify-between items-center mt-4">
            <h2 className="text-lg font-bold mb-2">
                {t('Orderdetails')}
              </h2>
  <button
    onClick={closeModal}
    className="bg-red-700 text-white px-4 mb-2 py-2 rounded"
  >
    <IoIosCloseCircle />

  </button>
           
              </div>
            <div className="border-y-2 py-8">
              <div className="grid grid-cols-3  items-center b ">
                <div className="flex items-center mb-2">
                  <FaRegClock className={`${selectedOrder.status === "Booked" ? statusStyles["Booked"].dark : statusStyles["Booked"].base} mr-1`} />
                  <span className={`${selectedOrder.status === "Booked" ? statusStyles["Booked"].dark : statusStyles["Booked"].base}`}>Booked</span>
                </div>
                <div className="flex items-center justify-center mb-2">
                  <FaTruck className={`${selectedOrder.status === "Dispatched" || selectedOrder.status === "Delivered" ? statusStyles["Dispatched"].dark : statusStyles["Dispatched"].base} mr-1`} />
                  <span className={`${selectedOrder.status === "Dispatched" || selectedOrder.status === "Delivered" ? statusStyles["Dispatched"].dark : statusStyles["Dispatched"].base}`}>Dispatched</span>
                </div>
                <div className="flex items-center justify-end mb-2">
                  <FaCheckCircle className={`${selectedOrder.status === "Delivered" ? statusStyles["Delivered"].dark : statusStyles["Delivered"].base} mr-1`} />
                  <span className={`${selectedOrder.status === "Delivered" ? statusStyles["Delivered"].dark : statusStyles["Delivered"].base}`}>Delivered</span>
                </div>
              </div>
              <div className="w-full h-3 bg-gray-300 rounded">
                <div className={`h-3 rounded ${getProgressWidth(delivery_status_enum(selectedOrder.delivery_status))}`}></div>
              </div>
            </div>

            {/* Order Details */}
            <div className="grid grid-cols-5 border-b-2 py-6 text-center gap-4 mb-4">
              <div>
                <strong>{t('deliveyaddrs')}</strong>
                <p className="text-sm text-gray-600">{selectedOrder.address}</p>
              </div>
              <div>
                <strong>{t('PurchaseDate')}</strong>
                <p className="text-sm text-gray-600">{selectedOrder.created_at}</p>
              </div>
              <div>
                <strong>{t('PaymentMethod')}</strong>
                <p className="text-sm text-gray-600"> {selectedOrder.paymentMethod ?? "Card Payment"}</p>
              </div>
              <div>
                <strong>Tracking Id</strong>
                <p className="text-sm text-gray-600"> {selectedOrder.tracking_id}</p>
              </div>
              <div>
                <strong>{t('BillingAddress')}</strong>
                <p className="text-sm text-gray-600">{selectedOrder.address}</p>
              </div>
            </div>

            {/* Item Details and Total Price Section */}
            <div className="flex mb-4 ">
              {/* Item Details */}
              <div className="flex-grow bg-white border overflow-auto max-h-[250px] border-gray-200 rounded-lg shadow sm:p-4 md:p-6">
                <h3 className="text-lg font-bold mb-2">{t('ItemDetails')}</h3>
                {selectedOrder.details.map((item, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <img src={item.images[0]} alt={item.title} className="w-12 h-12 mr-3" />
                    <div className="flex-grow flex justify-between">
                      <div className="font-semibold">{htmlToPlainText(item.title)}</div>
                      {/* <div>{item.quantity}</div>
                      <div>{item.price}</div> */}
                      <div>{item.quantity} x ${item.price}</div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Total Price Section */}
              <div className="ml-8 w-1/3 bg-white border border-gray-200 rounded-lg shadow sm:p-4 md:p-6">
                <h3 className="text-lg font-bold mb-2">{t('TotalPrice')}</h3>
                <div className="flex justify-between mb-1">
                  <span>{t('Subtotal')}</span>
                  <span className="text-md">${selectedOrder.final_amount}</span>
                </div>
                {/* <div className="flex justify-between mb-1">
                  <span>{t('tax')}</span>
                  <span className="text-slate-600 text-sm">{selectedOrder.tax}</span>
                </div> */}
                {/* <div className="flex justify-between mb-1">
                  <span>{t('DeliveryCharges')}</span>
                  <span className="text-slate-600 text-sm">{selectedOrder.deliveryCharges}</span>
                </div> */}
                <div className="flex justify-between font-bold">
                  <span className="text-lg">{t('Total')}</span>
                  <span className="text-lg">
                    {selectedOrder.final_amount}
                    {/* ${(parseFloat(calculateTotal(selectedOrder.items)) + parseFloat(selectedOrder.tax.replace('$', '')) + parseFloat(selectedOrder.deliveryCharges.replace('$', ''))).toFixed(2)} */}
                  </span>
                  
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center mt-4">
 <span></span>
  <span className="text-red-500">
    {t('(ForCancelinfo)')}
  </span>
</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyOrder;
