import React, { useState, useEffect } from "react";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import axios from "axios";
import BASE_URL from "../constant";
import LoadingPopup from "./LoadingPopup";


import { IoMdConstruct } from "react-icons/io";

const Contact = () => {
 

  const FAQComponent = () => {
    const [faqData, setFaqData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false); // Added error state
    const [activeTab, setActiveTab] = useState(null);
    const [savedLang, setSaveLang] = useState(localStorage.getItem("language") || "en");
    const [ques, setQues] = useState([]);
    const [ans, setAns] = useState([]);
  
    // Fetch FAQ data from the API
    useEffect(() => {
      const fetchFaqData = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/api_faqs.php`);
          setFaqData(response.data);
        } catch (error) {
          console.error("Error fetching FAQ data:", error);
          setError(true); // Set error state if fetching fails
        } finally {
          setLoading(false);
        }
      };
  
      fetchFaqData();
    }, []);
  
    // Update questions and answers when language or faqData changes
    useEffect(() => {
      if (faqData.questions) {
        const language = localStorage.getItem("language") || "en";
        setSaveLang(language);
        setQues(language === "en" ? faqData.questions : faqData.questions_is);
        setAns(language === "en" ? faqData.answers : faqData.answers_is);
      }
    }, [faqData]);
  
    if (loading) return <div>Loading...</div>; // Show loading state
    if (error) return <div>Error loading FAQ data!</div>; // Show error state if fetching fails
  
    const handleTabClick = (tabKey) => {
      setActiveTab(activeTab === tabKey ? null : tabKey);
    };
  
    return (
      <div className="faq-container max-h-[600px] overflow-y-auto p-4">
        <div className="questions-container max-h-[400px] overflow-y-auto mb-4">
          {ques.map((question, index) => {
            const answer = ans[index];
            const tabKey = `faq-${index}`;
  
            return (
              <div
                key={tabKey}
                className="faq-tab mb-4 border-b pb-2 rounded-lg border-gray-300"
              >
                <div
                  className={`tab-header cursor-pointer font-semibold text-lg flex justify-between items-center py-2 px-4 rounded-lg ${
                    activeTab === tabKey ? "tab-head-active bg-gray-100" : ""
                  }`}
                  onClick={() => handleTabClick(tabKey)}
                >
                  <p className="text-left capitalize">{question}</p>
                  <span>{activeTab === tabKey ? "▲" : "▼"}</span>
                </div>
                {activeTab === tabKey && (
                  <div className="tab-content mt-2 text-gray-700 px-4 py-2 bg-gray-50 rounded-lg">
                    <p>{answer}</p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  

  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const closeAlertTabModal = () => {
    setAlertItabOpen(false);
  };
  const [isAlerttabOpen, setAlertItabOpen] = useState(false);
  const handleTabClick = (index) => {
    setActiveTab(activeTab === index ? null : index);
  };

  const isValidEmail = (email) => {
    // Use a regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  useEffect(() => {
    setAlertItabOpen(true);
  }, []);
  const SimpleSend = async (e) => {
    e.preventDefault();
    if (!email) {
      alert("Please fill in the email field");
      return;
    }
    if (!isValidEmail(email)) {
      alert("Please fill in a valid email address");
      return;
    }
    if (!name) {
      alert("Please Enter your Name ");
      return;
    }
    setLoading(true);
    console.log({ email: email, name: name, Msg: name });
    const userData = {
      client_name: name,
      client_email: email,
      status: "open",
      message: msg,
    };
    console.log(userData);
    try {
      const response = await axios.post(
        `${BASE_URL}/api_contact_us.php`,
        userData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      // Check if the API request was successful (status code 2xx)
      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        console.log("API Response:", response.data);
        // addDataToFirestore(`${name} Sent a Quick Message in 5min Chat`,);
        alert(
          "Your message has been sent successfully. You will receive a reply via email soon."
        );
        // window.location.reload();
      } else {
        setLoading(false);
        console.error("API Request failed with status:", response.status);
        alert(
          "An error occurred while processing your request. Please try again later."
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("An unexpected error occurred:", error.message);
      alert(
        "An unexpected error occurred. Please try again later. ",
        error.message
      );
    }
  };
  return (
    <>
      <div className="flex justify-center items-center mt-10 w-[100%] ">
        {loading && <LoadingPopup />}
        {isAlerttabOpen && (
          <div className="fixed  inset-0 flex items-center justify-center z-[199]">
            {/* Background overlay */}
            <div className="absolute inset-0 bg-gray-500 opacity-95"></div>

            {/* Modal dialog */}
            <div className="relative w-auto overflow-auto bg-white h-auto bg-white w-1/2  rounded-lg shadow-lg  pt-0">
              {/* Header */}

              {/* Content */}
              <div dir="rtl" className="modal-books bg-white">
                <div class="flex flex-grow items-center  justify-center">
                  <div class="rounded-lg  p-8 text-center shadow-xl">
                    <IoMdConstruct
                      style={{
                        height: "150px",
                        width: "150px",
                        color: "blue",
                      }}
                      className="m-auto"
                    />
                    <p class="text-gray-600">
                      This Website is under Development we are constently{" "}
                    </p>
                    <p class="text-gray-600">adding new book every day </p>
                    <button
                      onClick={closeAlertTabModal}
                      type="button"
                      class="text-white m-4 bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                    >
                      {t("Close")}
                    </button>
                  </div>
                </div>
              </div>
              {/* Footer */}
            </div>
          </div>
        )}
        <div className=" p-4 py-2  w-[100%] flex">
          <div className="w-1/2">
            <p className="text-left capitalize mb-4 sm:text-[29.38px] md:text-[31.38px] text-[33.38px] text-black-900 w-auto font-bold font-lato">
              FAQ
            </p>

            <FAQComponent />

            {/* <img src="https://via.placeholder.com/400" alt="Stock" className="w-full h-auto rounded-md" /> */}
          </div>
          <div className="w-1/2 p-8">
            <h2 className="text-2xl font-semibold mb-4">{t("contact_us")}</h2>
            <div className="mb-4">
              {/* <div className="flex items-center mb-2">
              <FaPhone className="mr-2" />
              <span>123-456-7890</span>
            </div> */}
              <div className="flex items-center mb-2">
                <FaEnvelope className="mr-2" />
                <span>info@oizvehadar.com</span>
              </div>
              <div className="flex items-center">
                <FaMapMarkerAlt className="mr-2" />
                <span>8 Truman Ave Spring Valley New York 10977</span>
              </div>
            </div>
            <form>
              <div className="mb-4">
                <label className="block mb-2" htmlFor="name">
                  {t("name")}
                </label>
                <input
                  type="text"
                  id="name"
                  placeholder={t("name")}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2" htmlFor="email">
                  {t("email")}
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder={t("email")}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2" htmlFor="message">
                  {t("msg")}
                </label>
                <textarea
                  id="message"
                  placeholder={t("msg")}
                  onChange={(e) => setMsg(e.target.value)}
                  className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
                ></textarea>
              </div>
              <button
                type="submit"
                className="bg-red-900 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-blue-600"
                onClick={SimpleSend}
              >
                {t("send")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contact;
