import { FiBook } from "react-icons/fi";
import React, { useRef, useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../constant";
import { AuthContext } from "../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import htmlToPlainText from "../htmltotext";
import FlipCountdown from "@rumess/react-flip-countdown";

const Bundles = () => {
  const [bundles, setBundles] = useState([]);
  const {
    cart,
    addBundleToCart,
    removeFromCart,
    addToCart,
    currentUser,
    getUserData,
  } = useContext(AuthContext);
  const [symbol, setSymbol] = useState("$");
  const [price, setPrice] = useState();

  const [savedLang, setSaveLang] = useState(
    localStorage.getItem("language") || "en"
  );
  const [savedCurrency, setSavedCurrency] = useState(
    localStorage.getItem("currency") || "$"
  );
  useEffect(() => {
    const svLang = localStorage.getItem("language");
    setSaveLang(svLang);
    const svcCrrency = localStorage.getItem("currency");
    // setSaveLang(svcCrrency);
    
    // console.log(savedLang);
    setSymbol(svcCrrency !== "ils" ? "$" : "₪");
  //   if (bundles.length > 0) {
  //  setPrice(svcCrrency !== "ils" ?bundle.price : bundle.price_is);
  //   }
  });
  useEffect(() => {
    fetchBundles();
  }, []);
  const navigate = useNavigate();
  const fetchBundles = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api_book.php/bundlebooks`);
      const bookData = response.data;

      if (Array.isArray(bookData)) {
        // Filter out books with status 'deactive'
        const activeBooks = bookData.filter(book => book.status !== 'deactive');
        setBundles(activeBooks);
      } else {
        console.error("Expected an array of books");
      }
    } catch (error) {
      console.error("Error fetching books:", error);
    }
  };

  const purchase = async (e, pro) => {
    e.preventDefault();
    if (currentUser) {
      const data = {
        product: {
          ...pro,
          id: `bdl-${pro.id}`,
          title: `Bundle :${pro.bundle_name}`,
        },
        quantity: 1,
        price: pro.price,
      };
      addToCart(data);
      // navigate('/pages/Stripe',{state:{
      //   product:pro,
      //   amount:pro.price,
      //   type:"bundle"
      //  }} );
    } else {
      toast.error(`Your need to login first`);
    }
    // addBundleToCart(pro);
  };
  const { t } = useTranslation();
  return (
    <div className="container min-h-[800px] mx-auto ">
      {/* // <ToastContainer/> */}
      <h1 className="text-3xl font-bold text-center mt-8 mb-4">
        {" "}
        {t("book_bundle")}
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 ">
        {bundles.map((bundle, index) => (
          <div key={index} className="bg-gray-100 shadow-md p-4 rounded-lg">
            <div class="flex justify-center items-center">
              <h1 class="text-2xl tilt-neon mb-2 text-center">
                {bundle.bundle_name}
              </h1>
            </div>

            <div>
              <p className="mb-2 ml-2 text-2l tilt-neon font-bold">
                {bundle.percentage ? (
                  <div
                    className="flex"
                    dir={savedLang !== "en" ? "rtl" : "ltr"}
                  >
                    {bundle.percentage ? (
                      <>
                        <span className="line-through text-red-500 mr-2">
                          {savedLang !== "en" ? (
                            <>
                              {(
                                (symbol!== "₪" ?bundle.price : bundle.price_is) /
                                (1 - bundle.percentage / 100)
                              ).toFixed(2)}{" "}
                              <span className="text-l">{symbol}</span>
                            </>
                          ) : (
                            <>
                              <span className="text-l">{symbol}</span>{" "}
                              {(
                                 (symbol!== "₪" ?bundle.price : bundle.price_is)/
                                (1 - bundle.percentage / 100)
                              ).toFixed(2)}
                            </>
                          )}
                        </span>

                        <span className="ml-4">
                          {savedLang !== "en" ? (
                            <>
                              {symbol!== "₪" ?bundle.price : bundle.price_is} <span className="text-l">{symbol}</span>
                            </>
                          ) : (
                            <>
                              <span className="text-l">{symbol}</span> {symbol!== "₪" ?bundle.price : bundle.price_is}
                            </>
                          )}
                        </span>
                      </>
                    ) : (
                      <span>
                        {savedLang !== "en" ? (
                          <>
                            {symbol!== "₪"?bundle.price : bundle.price_is} <span className="text-l">{symbol}</span>
                          </>
                        ) : (
                          <>
                            <span className="text-l">{symbol}</span> {symbol!=="₪"?bundle.price : bundle.price_is}
                          </>
                        )}
                      </span>
                    )}
                  </div>
                ) : (
                  <>
                    <span className="text-l ">{symbol}</span> {symbol!== "₪" ?bundle.price : bundle.price_is}
                  </>
                )}
              </p>
            </div>
            <h2 class="mb-2 tilt-neon text-white bg-red-400 px-2 py-1 rounded inline-block">
  {bundle.percentage}% {t("OFF")}
</h2>

<div style={{ minHeight: '100px' }}> {/* Adjust minHeight as needed */}
  {bundle.end_at ? (
    <div>
      <h2>{t("deal_expire")}</h2>
      <h2 className="mb-2 tilt-neon text-gray-600" style={{ direction: "ltr" }}>
        <FlipCountdown
          size="small"
          yearTitle="Year"
          hideYear
          // hideMonth
          endAt={`${bundle.end_at}`} // Date/Time
        />
      </h2>
      <div className="grid grid-cols-2 mt-4 gap-2 h-[120px] overflow-y-auto">
              {bundle.books.map((book, index) => (
                <div key={index} className="flex">
                  <img
                    src={book.images[0]}
                    alt={book.title}
                    className="w-10 h-10 mr-2"
                  />
                  <div>
                    <p>{htmlToPlainText(book.title)}</p>
                    {/* <p className="text-gray-600">{book.price}</p> */}
                  </div>
                </div>
              ))}
            </div>

    </div>
  ) : (
    <div> <div className="grid grid-cols-2 mt-4 gap-2 h-[200px] overflow-y-auto">
    {bundle.books.map((book, index) => (
      <div key={index} className="flex">
        <img
          src={book.images[0]}
          alt={book.title}
          className="w-10 h-10 mr-2"
        />
        <div>
          <p>{htmlToPlainText(book.title)}</p>
          {/* <p className="text-gray-600">{book.price}</p> */}
        </div>
      </div>
    ))}
  </div>
</div>
    
  )}
</div>


           
            <div className="flex justify-center">
  <button
    className="bg-red-900 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full mt-4 inline-block items-center"
    onClick={(e) => purchase(e, bundle)}
  >
    {t("buy_bundles")}
  </button>
</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Bundles;
