import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { Link } from "react-router-dom";
import { FiX } from "react-icons/fi"; // FiX icon from react-icons
import { FaCartPlus } from "react-icons/fa6";
import axios from "axios";
import BASE_URL from "./constant";
import { AuthContext } from "./context/AuthContext";
import htmlToPlainText from "./htmltotext";
import { t } from "i18next";

export default function AjaxCart() {
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState("0.00");
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(""); // Error state
  const { cart, addToCart, removeFromCart, currentUser, getUserData } =
    useContext(AuthContext);
  const [savedLang, setSaveLang] = useState(
    localStorage.getItem("currency") || "usd"
  );
  const [symbol, setSymbol] = useState("$");

  useEffect(() => {
    const svLang = localStorage.getItem("currency");
    setSaveLang(svLang);
    console.log(savedLang);
    setSymbol(svLang !== "ils" ? "$" : "₪");
    //  if(items.length==0){
    //    setPrice(svLang !=="ils" ? product.price : product.price_is);

    //  }
  });

  const fetchCart = async () => {
    setLoading(true);
    setError(""); // Reset error before fetch
    try {
      const userData = getUserData();
      console.log(userData.id)
      const response = await axios.get(
        `${BASE_URL}/api_cart.php?action=detailed_cart&user_id=${userData.id}&currency=${savedLang}`
      );
      const bookData = response.data;

      setProducts(bookData.cart.details);
      const totalSum = bookData.cart.details.reduce((total, item) => {
        const itemPrice = item.warehouse_quantity > 0 ? item.price : 0; // Choose the price based on language
        return total + item.quantity * parseFloat(itemPrice); // Multiply quantity by price and add to total
      }, 0);
      setTotal(parseFloat(totalSum).toFixed(2));
    } catch (error) {
      setProducts([]);
      setTotal("0.00");
      setError("Empty Cart"); // Set error message
      console.error("Error fetching books:", error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const [open, setOpen] = useState(false); // Initially closed

  const handleRemoveFromCart = async (product) => {
    try {
      await removeFromCart(product);
      fetchCart(); // Re-fetch cart after removing item
    } catch (error) {
      console.error("Failed to remove item:", error);
    }
  };

  const OpenCart = () => {
    fetchCart();
    setOpen(true);
  };
  return (
    <>
   <div className="inline-block bg-red-800 ml-2 text-white px-4 py-2 rounded-md shadow-md hover:bg-black relative" onClick={() => OpenCart()}>
  <FaCartPlus />
  
  
  {cart && cart.details.length > 0 && (
    <h1 className="absolute top-[-12px] right-[-12px] text-white bg-red-500 rounded-full p-1 text-xs px-2 py-1">
      {cart.details.length}
    </h1>
  )}
</div>

      <Dialog
        className="relative z-10 transition duration-500 ease-in"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition duration-800 ease-in-out" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <DialogPanel className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                    <div className="flex items-start justify-between">
                      <DialogTitle className="text-lg font-medium text-gray-900">
                        {t("shopping_cart")}
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <FiX className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>

                    <div className="mt-8">
                      <div className="flow-root">
                        <br />
                        {loading ? (
                          <p className="text-center">{t("loading")}</p>
                        ) : error ? (
                          <p className="text-center text-red-500">{error}</p>
                        ) : (
                          <ul
                            role="list"
                            className="-my-6 divide-y divide-gray-200"
                          >
                            {products.map((product) => (
                              <li key={product.id} className="flex py-6">
                                <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                  <img
                                    src={product.images[0]}
                                    alt={product.imageAlt}
                                    className="h-full w-full object-cover object-center"
                                  />
                                </div>

                                <div className="ml-4 flex flex-1 flex-col">
                                  <div>
                                    <div className="flex justify-between text-base font-medium text-gray-900">
                                      <h3>
                                        <a href={product.href}>
                                          {htmlToPlainText(product.title)}
                                        </a>
                                      </h3>

                                      <p
                                        className={`ml-4 ${
                                          product.warehouse_quantity > 0
                                            ? ""
                                            : "text-red-500"
                                        } text-sm`}
                                      >
                                        {product.warehouse_quantity > 0
                                          ? `${symbol} ${product.price}`
                                          :  t("outofstock")}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex flex-1 items-end justify-between text-sm">
                                    <p className="text-gray-500">
                                      Qty {product.quantity}
                                    </p>

                                    <div className="flex">
                                      <button
                                        type="button"
                                        className="font-medium text-indigo-600 hover:text-indigo-500"
                                        onClick={() =>
                                          handleRemoveFromCart(product)
                                        }
                                      >
                                        {t("remove")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                        <br />
                      </div>
                    </div>
                  </div>

                  <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                    <div className="flex justify-between text-base font-medium text-gray-900">
                      <p>{t("subtotal")}</p>
                      <p>
                        {symbol} {total}
                      </p>
                    </div>
                    <p className="mt-0.5 text-sm text-gray-500">
                    {t("shipping")}
                    </p>
                    <div className="mt-6">
                      <Link
                        to="/Checkout"
                        onClick={() => setOpen(false)}
                        className="flex items-center justify-center rounded-md border border-transparent bg-[#3b200f] px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-[#161210]"
                      >
                       {t("checkout")}
                      </Link>
                    </div>
                    <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                      <p>
                      {t("or")}{" "}
                        <button
                          type="button"
                          className="font-medium text-red-600 hover:text-red-500"
                          onClick={() => setOpen(false)}
                        >
                          {t("continue")}
                          <span aria-hidden="true"> &rarr;</span>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
